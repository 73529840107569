export const currencies = [
  {
    text: 'USD',
    value: 'USD',
  },
  {
    text: 'EUR',
    value: 'EUR',
  },
  {
    text: 'RUB',
    value: 'RUB',
  },
]
