<template>
  <div>
    <v-tabs
      v-model="tab"
      mobile-breakpoint="0"
      slider-color="blue"
      color="blue"
      background-color="transparent"
      class="mb-5 mb-sm-10"
      :vertical="checkXsOnly"
    >
      <v-tab v-for="item in tabs" :key="item.tab">
        {{ $t(item.tab) }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="py-10">
      <v-tab-item>
        <InfoTab
          ref="form"
          :formData="formData"
          :roles="roles"
          :currencies="currencies"
          :countries="countries"
          :isDisabled="isDisabled"
          @loadPermissions="loadPermissionsBuId"
        />
      </v-tab-item>
      <v-tab-item>
        <PermissionsTab
          :permissionsNames="permissionsNames"
          :permissionsTypes="permissionsTypes"
          :isDisabled="isDisabled"
        />
      </v-tab-item>
    </v-tabs-items>
    <div class="d-sm-flex justify-space-between mt-sm-10">
      <ActionButton outlined color="error" class="mb-5 mb-sm-0" @click="backHandler">
        {{ $t('button.back') }}
      </ActionButton>
      <div v-if="permission.update" class="d-sm-flex align-end">
        <v-slide-x-reverse-transition hide-on-leave>
          <ActionButton
            v-if="!isDisabled || !id"
            outlined
            :loading="actionLoading"
            class="mb-5 mb-sm-0"
            color="green"
            @click="save"
          >
            {{ $t('button.save') }}
          </ActionButton>
        </v-slide-x-reverse-transition>
        <div v-if="id" class="mb-5 mb-sm-0 ml-sm-5">
          <v-slide-x-reverse-transition hide-on-leave>
            <ActionButton v-if="isDisabled" key="edit" outlined :loading="actionLoading" @click="edit">
              {{ $t('button.edit') }}
            </ActionButton>
            <ActionButton v-else key="discard" outlined @click="discard">
              {{ $t('button.discard') }}
            </ActionButton>
          </v-slide-x-reverse-transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapState, mapGetters} from 'vuex'
import {currencies} from '@/helpers/variables/currencies'
import {countries} from '@/helpers/variables/countries'
import {getRoles} from '@/helpers/variables/roles'
import {encrypt, decrypt} from '@/helpers/cryptoStandards'
import {backHandler} from '@/helpers/handler/backHandler'

import * as manager from '@/helpers/permissions/manager'
import * as advertiserManager from '@/helpers/permissions/advertiserManager'
import * as advertiserPro from '@/helpers/permissions/advertiserPro'
import * as advertiser from '@/helpers/permissions/advertiser'

import InfoTab from '@/views/clients/part/InfoTab'
import PermissionsTab from '@/views/clients/part/PermissionsTab'

export default {
  name: 'ClientDetail',
  components: {InfoTab, PermissionsTab},
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
  },
  data: () => ({
    isDisabled: false,
    formData: {
      balance: 0,
      bidMargin: 50,
    },
    currencies: currencies,
    countries: countries,
    roles: [],
    tab: null,
    permissionsTypes: {},
    permissionsNames: {},
  }),
  computed: {
    ...mapState({
      item: (state) => state.user.item,
      actionLoading: (state) => state.user.actionLoading,
      isCheckLocale: (state) => state.isCheckLocale,
    }),
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      isManager: 'auth/isManager',
      isAdvertiserManager: 'auth/isAdvertiserManager',
      permission: 'user/permission',
    }),
    tabs() {
      return (this.isAdmin || this.isManager) && this.isCheckLocale
        ? [{tab: 'tabs.userInfo'}, {tab: 'tabs.userPermissions'}]
        : [{tab: 'tabs.userInfo'}]
    },
    checkXsOnly() {
      return this.$vuetify.breakpoint.xsOnly
    },
  },
  methods: {
    ...mapActions({
      getItemById: 'user/GET_ITEM_BY_ID',
      createUser: 'user/CREATE_USER',
      editUser: 'user/EDIT_USER',
    }),
    backHandler,
    async loadUser() {
      await this.getItemById(this.id)
      await this.setFormData()
    },
    setFormData() {
      this.formData = {
        ...this.item,
        role: this.item.role[0],
        encodePermissions: decrypt(this.item.encodePermissions),
      }
      this.loadPermissionsBuId(this.formData.role)
    },
    loadPermissionsBuId(role) {
      switch (role) {
        case 'ROLE_MANAGER':
          this.permissionsNames = manager.permissionsNames
          this.permissionsTypes = !this.id
            ? manager.permissionsTypes
            : this.reducePermissions(this.formData.encodePermissions)
          break
        case 'ROLE_ADVERTISER_MANAGER':
          this.permissionsNames = advertiserManager.permissionsNames
          this.permissionsTypes = !this.id
            ? advertiserManager.permissionsTypes
            : this.reducePermissions(this.formData.encodePermissions)
          break
        case 'ROLE_ADVERTISER_PRO':
          this.permissionsNames = advertiserPro.permissionsNames
          this.permissionsTypes = !this.id
            ? advertiserPro.permissionsTypes
            : this.reducePermissions(this.formData.encodePermissions)
          break
        case 'ROLE_ADVERTISER':
          this.permissionsNames = advertiser.permissionsNames
          this.permissionsTypes = !this.id
            ? advertiser.permissionsTypes
            : this.reducePermissions(this.formData.encodePermissions)
          break
        default:
          this.permissionsNames = []
          this.permissionsTypes = []
      }
    },
    setRoles() {
      switch (true) {
        case this.isAdmin:
          this.roles = getRoles(['ROLE_ADMIN'])
          break
        case this.isManager:
          this.roles = getRoles(['ROLE_ADMIN', 'ROLE_MANAGER'])
          break
        case this.isAdvertiserManager:
          this.roles = getRoles(['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_ADVERTISER_MANAGER'])
          break
        default:
          return this.roles
      }
    },
    edit() {
      this.isDisabled = !this.isDisabled
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.formData = {
          ...this.formData,
          encodePermissions: await this.createPermissions(),
        }
        const resp = this.id ? await this.editUser(this.formData) : await this.createUser(this.formData)
        if (resp?.status === 200) await this.backHandler()
      }
    },
    createPermissions() {
      let permissions = []
      for (let type in this.permissionsTypes) {
        if (this.permissionsTypes[type]) {
          permissions.push(type)
        }
      }
      return encrypt(JSON.stringify(permissions))
    },
    reducePermissions(data) {
      return data.reduce((obj, name) => {
        obj[name] = true
        return obj
      }, {})
    },
    discard() {
      this.reset()
      this.setFormData()
    },
    reset() {
      this.$refs.form.resetValidation()
      this.isDisabled = !this.isDisabled
    },
  },
  mounted() {
    if (this.id) {
      this.loadUser()
      this.isDisabled = true
    }
    this.setRoles()
  },
}
</script>
