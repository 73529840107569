export const permissionsTypes = {
  DOCS_READ: true,
  DOCS_READ_ALL: true,
  RULE_CREATE: false,
  RULE_READ: true,
  RULE_READ_ALL: true,
  RULE_UPDATE: false,
  RULE_REMOVE: false,
  TARGET_CREATE: true,
  TARGET_READ: true,
  TARGET_READ_ALL: true,
  TARGET_UPDATE: true,
  TARGET_REMOVE: true,
  CLIENT_TARGET_CREATE: false,
  CLIENT_TARGET_UPDATE: false,
  CLIENT_TARGET_REMOVE: false,
  CLIENT_CREATIVE_CREATE: false,
  CLIENT_CREATIVE_UPDATE: false,
  CLIENT_CREATIVE_REMOVE: false,
  CREATIVE_CREATE: true,
  CREATIVE_READ: true,
  CREATIVE_READ_ALL: true,
  CREATIVE_UPDATE: true,
  CREATIVE_REMOVE: true,
  CREATIVE_ADD_VAST_DAAST: false,
  CAMPAIGN_CREATE: true,
  CAMPAIGN_READ: true,
  CAMPAIGN_READ_ALL: true,
  CAMPAIGN_UPDATE: true,
  CAMPAIGN_REMOVE: true,
  CLIENT_READ: true,
  CLIENT_READ_ALL: true,
  CLIENT_CREATE: true,
  SEGMENT_READ: true,
  SEGMENT_READ_ALL: true,
  STATISTIC_READ: true,
  STATISTIC_READ_ALL: true,
}

export const permissionsNames = {
  DOCS: ['DOCS_READ', 'DOCS_READ_ALL'],
  RULE: ['RULE_CREATE', 'RULE_UPDATE', 'RULE_REMOVE', 'RULE_READ', 'RULE_READ_ALL'],
  TARGET: ['TARGET_CREATE', 'TARGET_READ', 'TARGET_READ_ALL', 'TARGET_UPDATE', 'TARGET_REMOVE'],
  CREATIVE: [
    'CREATIVE_CREATE',
    'CREATIVE_READ',
    'CREATIVE_READ_ALL',
    'CREATIVE_UPDATE',
    'CREATIVE_REMOVE',
    'CREATIVE_ADD_VAST_DAAST',
  ],
  CAMPAIGN: ['CAMPAIGN_CREATE', 'CAMPAIGN_READ', 'CAMPAIGN_READ_ALL', 'CAMPAIGN_UPDATE', 'CAMPAIGN_REMOVE'],
  SEGMENT: ['SEGMENT_READ', 'SEGMENT_READ_ALL'],
  STATISTIC: ['STATISTIC_READ', 'STATISTIC_READ_ALL'],
  CLIENT: [
    'CLIENT_TARGET_CREATE',
    'CLIENT_TARGET_UPDATE',
    'CLIENT_TARGET_REMOVE',
    'CLIENT_CREATIVE_CREATE',
    'CLIENT_CREATIVE_UPDATE',
    'CLIENT_CREATIVE_REMOVE',
    'CLIENT_READ',
    'CLIENT_READ_ALL',
    'CLIENT_CREATE',
  ],
}
