<template>
  <div>
    <div v-if="Object.keys(permissionsNames).length !== 0">
      <v-expansion-panels :value="openPanelsIdx" multiple>
        <v-expansion-panel v-for="(permissions, key) in permissionsNames" :key="key">
          <v-expansion-panel-header class="expansion-title">
            {{ $t('permissions.' + key) }}
            <template #actions>
              <v-icon color="black"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="justify-space-between">
              <v-col
                v-for="(name, i) in permissions"
                :key="'permission_' + i"
                cols="12"
                md="6"
                lg="5"
                class="d-flex justify-space-between align-center px-5 px-sm-15 mb-7"
              >
                <div class="pr-15">{{ $t('permissions.' + name) }}</div>
                <div>
                  <CustomSwitch v-model="permissionsTypes[name]" :disabled="isDisabled" />
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <v-row v-else>
      <v-col cols="12" class="text-center">
        <h4>{{ $t('service.roleEmpty') }}</h4>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'PermissionsTab',
  props: {
    permissionsNames: {
      type: Object,
      default: () => {},
    },
    permissionsTypes: {
      type: Object,
      default: () => {},
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    openPanelsIdx: [0],
  }),
}
</script>
