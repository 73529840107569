export const countries = [
  {
    text: 'countries.ru',
    value: 'RU',
  },
  {
    text: 'countries.by',
    value: 'BY',
  },
  {
    text: 'countries.lt',
    value: 'LT',
  },
]
