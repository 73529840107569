export const permissionsTypes = {
  DOCS_CREATE: false,
  DOCS_READ: true,
  DOCS_READ_ALL: true,
  DOCS_UPDATE: false,
  DOCS_REMOVE: false,
  RULE_CREATE_FOR_CLIENT: true,
  RULE_READ: true,
  RULE_READ_ALL: true,
  RULE_UPDATE: true,
  RULE_REMOVE: true,
  TARGET_CREATE_FOR_CLIENT: true,
  TARGET_READ: true,
  TARGET_READ_ALL: true,
  TARGET_UPDATE: true,
  TARGET_REMOVE: true,
  CREATIVE_CREATE_FOR_CLIENT: true,
  CREATIVE_MODERATE: true,
  CREATIVE_READ: true,
  CREATIVE_READ_ALL: true,
  CREATIVE_UPDATE: true,
  CREATIVE_REMOVE: true,
  CREATIVE_ADD_VAST_DAAST: true,
  CAMPAIGN_CREATE_FOR_CLIENT: true,
  CAMPAIGN_READ: true,
  CAMPAIGN_READ_ALL: true,
  CAMPAIGN_UPDATE: true,
  CAMPAIGN_REMOVE: true,
  CLIENT_READ: true,
  CLIENT_READ_ALL: true,
  CLIENT_CREATE: true,
  CLIENT_UPDATE: false,
  CLIENT_BALANCE_UPDATE: false,
  SEGMENT_UPDATE: true,
  SEGMENT_CREATE: true,
  SEGMENT_REMOVE: true,
  SEGMENT_READ: true,
  SEGMENT_READ_ALL: true,
  TAG_READ: true,
  TAG_READ_ALL: true,
  TAG_UPDATE: true,
  TAG_CREATE: true,
  TAG_REMOVE: true,
  EXCHANGE_RATE_CREATE: false,
  EXCHANGE_RATE_READ_ALL: false,
  EXCHANGE_RATE_UPDATE: false,
  STATISTIC_READ: true,
  STATISTIC_READ_ALL: true,
}

export const permissionsNames = {
  DOCS: ['DOCS_CREATE', 'DOCS_UPDATE', 'DOCS_REMOVE', 'DOCS_READ', 'DOCS_READ_ALL'],
  RULE: ['RULE_CREATE_FOR_CLIENT', 'RULE_UPDATE', 'RULE_REMOVE', 'RULE_READ', 'RULE_READ_ALL'],
  TARGET: ['TARGET_CREATE_FOR_CLIENT', 'TARGET_READ', 'TARGET_READ_ALL', 'TARGET_UPDATE', 'TARGET_REMOVE'],
  CREATIVE: [
    'CREATIVE_CREATE_FOR_CLIENT',
    'CREATIVE_MODERATE',
    'CREATIVE_READ',
    'CREATIVE_READ_ALL',
    'CREATIVE_UPDATE',
    'CREATIVE_REMOVE',
    'CREATIVE_ADD_VAST_DAAST',
  ],
  CAMPAIGN: [
    'CAMPAIGN_CREATE_FOR_CLIENT',
    'CAMPAIGN_READ',
    'CAMPAIGN_READ_ALL',
    'CAMPAIGN_UPDATE',
    'CAMPAIGN_REMOVE',
  ],
  SEGMENT: ['SEGMENT_UPDATE', 'SEGMENT_CREATE', 'SEGMENT_REMOVE', 'SEGMENT_READ', 'SEGMENT_READ_ALL'],
  STATISTIC: ['STATISTIC_READ', 'STATISTIC_READ_ALL'],
  CLIENT: ['CLIENT_READ', 'CLIENT_READ_ALL', 'CLIENT_CREATE', 'CLIENT_UPDATE', 'CLIENT_BALANCE_UPDATE'],
  TAG: ['TAG_READ', 'TAG_READ_ALL', 'TAG_UPDATE', 'TAG_CREATE', 'TAG_REMOVE'],
  EXCHANGE_RATE: ['TAG_READ', 'EXCHANGE_RATE_CREATE', 'EXCHANGE_RATE_READ_ALL', 'EXCHANGE_RATE_UPDATE'],
  INTEGRATION: ['INTEGRATION_READ'],
}
