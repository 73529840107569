<template>
  <v-form v-model="isValid" ref="form" lazy-validation>
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="3">
        <CustomInput
          v-model="formData.firstName"
          id="firstName"
          name="firstName"
          :label="$t('label.firstName')"
          :rules="firstNameRules"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <CustomInput
          v-model="formData.lastName"
          id="lastName"
          name="lastName"
          :label="$t('label.lastName')"
          :rules="lastNameRules"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <CustomInput
          v-model="formData.city"
          id="city"
          name="city"
          :label="$t('label.city')"
          :rules="cityRules"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <CustomInput
          v-model="formData.email"
          id="email"
          name="email"
          autocomplete="email"
          :label="$t('label.email')"
          :rules="emailRules"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <CustomInput
          v-model="formData.phone"
          id="phone"
          name="phone"
          autocomplete="phone"
          :label="$t('label.phone')"
          :rules="phoneRules"
          :disabled="isDisabled"
          @keypress="onlyNumbers"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <CustomInput
          v-model="formData.company"
          id="company"
          name="company"
          :label="$t('label.company')"
          :rules="companyRules"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <CustomInput
          v-model="formData.address"
          id="address"
          name="address"
          :label="$t('label.address')"
          :rules="addressRules"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <CustomSelect
          v-model="formData.country"
          id="country"
          name="country"
          :label="$t('label.country')"
          :rules="countryRules"
          :items="countries"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <CustomInput
          v-model="formData.contractNumber"
          id="contractNumber"
          name="contractNumber"
          :label="$t('label.contractNumber')"
          :rules="contractNumberRules"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <CustomInput
          v-model="formData.balance"
          id="balance"
          name="balance"
          type="number"
          :label="$t('label.balance')"
          :rules="balanceRules"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <CustomInput
          v-model="formData.bidMargin"
          id="bidMargin"
          name="bidMargin"
          type="number"
          :label="$t('label.bidMargin')"
          :rules="bidMarginRules"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <CustomSelect
          v-model="formData.currency"
          id="currency"
          name="currency"
          :label="$t('label.currency')"
          :rules="currencyRules"
          :items="currencies"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <CustomSelect
          v-model="formData.role"
          id="role"
          name="role"
          :label="$t('label.role')"
          :rules="roleRules"
          :items="roles"
          :disabled="isDisabled"
          @input="$emit('loadPermissions', $event)"
        />
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import {mapState} from 'vuex'
import onlyNumbers from '@/helpers/onlyNumbers'

export default {
  name: 'InfoTab',
  components: {},
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    roles: {
      type: Array,
      default: () => [],
    },
    currencies: {
      type: Array,
      default: () => [],
    },
    countries: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isValid: false,
  }),
  computed: {
    ...mapState({
      isCheckLocale: (state) => state.isCheckLocale,
    }),
    firstNameRules() {
      return this.isCheckLocale ? [(v) => !!v || this.$t('rules.firstNameRequired')] : []
    },
    lastNameRules() {
      return this.isCheckLocale ? [(v) => !!v || this.$t('rules.lastNameRequired')] : []
    },
    cityRules() {
      return this.isCheckLocale ? [(v) => !!v || this.$t('rules.cityRequired')] : []
    },
    phoneRules() {
      return this.isCheckLocale ? [(v) => !!v || this.$t('rules.phoneRequired')] : []
    },
    companyRules() {
      return this.isCheckLocale ? [(v) => !!v || this.$t('rules.companyRequired')] : []
    },
    addressRules() {
      return this.isCheckLocale ? [(v) => !!v || this.$t('rules.addressRequired')] : []
    },
    countryRules() {
      return this.isCheckLocale ? [(v) => !!v || this.$t('rules.countryRequired')] : []
    },
    emailRules() {
      return this.isCheckLocale
        ? [
            (v) => !!v || this.$t('rules.emailRequired'),
            (v) =>
              /^([a-zA-Z0-9_\-\\./]+)@([a-zA-Z0-9_\-\\./]+)\.([a-zA-Z]{2,10})$/.test(v) ||
              this.$t('rules.emailValid'),
          ]
        : []
    },
    contractNumberRules() {
      return this.isCheckLocale ? [(v) => !!v || this.$t('rules.contractNumberRequired')] : []
    },
    balanceRules() {
      return this.isCheckLocale ? [(v) => !!v || v === 0 || this.$t('rules.balanceRequired')] : []
    },
    bidMarginRules() {
      return this.isCheckLocale ? [(v) => !!v || v === 0 || this.$t('rules.bidMarginRequired')] : []
    },
    currencyRules() {
      return this.isCheckLocale ? [(v) => !!v || v === 0 || this.$t('rules.currencyRequired')] : []
    },
    roleRules() {
      return this.isCheckLocale ? [(v) => !!v || v === 0 || this.$t('rules.roleRequired')] : []
    },
  },
  methods: {
    onlyNumbers,
    validate() {
      return this.$refs.form.validate()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
  },
}
</script>
